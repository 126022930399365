import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router';
import { createSelector } from 'reselect';
import cn from 'classnames';
import Button from '@rambler-components/button';
import Tooltip from '@rambler-components/tooltip';

import { Icon } from 'common/components/Icon';
import { TarotMenu } from 'common/components/Tarot/TarotMenu';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';
import { sentryReactSend } from 'utils/sentry/client';
import { TOOLTIP_STORAGE_VALUE } from 'config/constants/tarot';

import s from './styles.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.isMobile,
  ],
  isMobile => ({
    isMobile,
  }),
);

const BUTTON_STYLES = {
  height:       55,
  width:        55,
  borderRadius: '100%',
  position:     'fixed',
  /* Не должен перекрывать меню, сообщение о PWA, но должен перекрывать селекты */
  zIndex:       300,
  boxShadow:    '0px 10px 30px -5px rgba(49, 94, 251, 0.25)',
} as const;

const TarotMenuWithButton: React.FC = memo(() => {
  const { isMobile } = useSelector(selectData);
  const [isOpen, setIsOpen] = useState(false);
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const [bottomPosition, setBottomPosition] = useState(isMobile ? '20%' : '15%');

  const { pathname } = useLocation();

  useEffect(() => {
    // при переходе между страницами карт меню не схлопывалось тк SPA
    setIsOpen(false);
  }, [pathname]);

  useEffect(() => {
    // на мобилках при скролле меняется высота вьюпорта,
    // позиционирование в процентах вызывает дерганья при скролле
    // поэтому важно получать в пикселях
    // посмотреть можно тут https://jira.rambler-co.ru/browse/HORO-5719 п5
    if (isMobile) {
      setBottomPosition(`${(window.innerHeight * 20) / 100}px`);
    }
  }, [isMobile]);

  const {
    top100Prefix,
  } = useTop100Context();

  useEffect(() => {
    try {
      const isShowed = window.localStorage.getItem(TOOLTIP_STORAGE_VALUE) === '1';

      if (!isShowed && isMobile) {
        setIsShowTooltip(true);
      }
    } catch (error) {
      sentryReactSend(error);
      // eslint-disable-next-line no-console
      console.error(`${new Date()} ${error}`);
    }
  }, [isMobile]);

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (isShowTooltip) {
      try {
        // Когда пользователь впервые заходит на страницу, ему показывается тултипная подсказка.
        // Она видна 5 сек, после чего исчезает сама. Отображается только один раз
        timerId = setTimeout(() => {
          window.localStorage.setItem(TOOLTIP_STORAGE_VALUE, '1');
          setIsShowTooltip(false);
        }, 5000);
      } catch (error) {
        sentryReactSend(error);
        // eslint-disable-next-line no-console
        console.error(`${new Date()} ${error}`);
      }
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [isShowTooltip]);

  useEffect(() => {
    const onEscape = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        setIsOpen(false);
      }
    };

    window.addEventListener('keydown', onEscape);

    return () => window.removeEventListener('keydown', onEscape);
  }, []);

  const openMenu = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openButton = (
    <Button
      onClick={openMenu}
      key={String(__BROWSER__)}
      style={{
        ...BUTTON_STYLES,
        ...(isMobile
          ? { bottom: bottomPosition, right: 15 }
          : { bottom: bottomPosition, left: 15 }
        ),
      }}
      className={cn(s.button, isMobile ? s.buttonMobile : s.buttonDesktop)}
      type="primary"
      aria-label="Открыть меню карт таро"
      {...getTop100Markup(isMobile, `${top100Prefix}`, 'tarot_navigation::menu_open')}
    >
      <Icon id="list" style={{ width: 20, height: 20 }} />
    </Button>
  );

  return (
    <>
      {isShowTooltip ? (
        <Tooltip
          label="Выбрать карту"
          autoPosition
          isAlwaysCentered
          forceVisible
        >
          {openButton}
        </Tooltip>
      ) : openButton}

      {/* Через портал тк когда приходит брендинг топлайн перекрывает меню */}
      {isOpen && (createPortal(
        <div className={s.wrapper}>
          <Button
            onClick={closeMenu}
            style={{
              ...BUTTON_STYLES,
              ...(isMobile ? { bottom: bottomPosition, right: 15 } : { top: 30, right: 30 }),
            }}
            type="white"
            aria-label="Закрыть меню карт таро"
            {...getTop100Markup(isMobile, `${top100Prefix}`, 'tarot_navigation::menu_close')}
          >
            <Icon id="close" style={{ width: 20, height: 20 }} />
          </Button>

          <TarotMenu isMobile={isMobile} top100Prefix={`${top100Prefix}::tarot_navigation`} isCompact />
        </div>,
        document.getElementById('root')!,
      ))}
    </>
  );
});

export { TarotMenuWithButton };
