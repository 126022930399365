import React from 'react';
import cn from 'classnames';

import { CENTERS_DATA } from 'config/constants/humanDesign';

import { Typography } from 'common/components/Typography';

import s from './styles.css';

interface IHumanDesignEnergyCenters {
  definedCenters?: HumanDesignResultType['defined_centers']
  isMobile: boolean
  className?: string
}

function HumanDesignEnergyCenters({
  definedCenters,
  isMobile,
  className,
}: IHumanDesignEnergyCenters) {
  if (!definedCenters) return null;

  return (
    <div className={cn(s.info, className)}>
      {Object.keys(CENTERS_DATA).map(key => {
        const isDefined = definedCenters.includes(key);

        return (
          <div
            className={cn(
              s.infoLine,
              isMobile ? s.infoLineMobile : s.infoLineDesktop,
            )}
            key={key}
          >
            <Typography
              variant="defaultBold"
              className={s.infoTitle}
            >
              {CENTERS_DATA[key].title}
            </Typography>
            <Typography
              variant="defaultMedium"
              className={s.infoDefinition}
            >
              {isDefined ? 'Определен' : 'Не определен'}
            </Typography>
            <Typography
              variant="defaultMedium"
              className={s.infoValue}
            >
              {CENTERS_DATA[key].description}
            </Typography>
          </div>
        );
      })}
    </div>
  );
}

HumanDesignEnergyCenters.defaultProps = {
  definedCenters: null,
  className:      '',
};

export { HumanDesignEnergyCenters };
