import React, { Fragment } from 'react';
import { useLocation } from 'react-router';
import loadable from '@loadable/component';

import { Ad } from 'common/components/Ad';
import { tarotList } from 'config/constants/tarot';

import s from './styles.css';

const TarotCategory = loadable(() => import('../TarotCategory'));

interface ITarotCardsProps {
  isMobile: boolean;
}

function TarotCards({ isMobile }: ITarotCardsProps) {
  const { pathname } = useLocation();
  const activeCategory = tarotList.find(({ slug }) => pathname.includes(slug));

  if (activeCategory) {
    return <TarotCategory isMobile={isMobile} {...activeCategory} />;
  }

  return (
    <>
      {tarotList.map((sectionProps, index) => {
        const isLastSection = index === tarotList.length - 1;

        if (isLastSection) {
          return <TarotCategory key={sectionProps.title} isMobile={isMobile} {...sectionProps} />;
        }

        return (
          <Fragment key={sectionProps.title}>
            <TarotCategory isMobile={isMobile} {...sectionProps} />
            <Ad name={isMobile ? 'content4' : 'center'} wrapperClassName={s.ad} />
          </Fragment>
        );
      })}
    </>
  );
}

export { TarotCards };
