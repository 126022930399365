import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';

import { Icon } from 'common/components/Icon';
import { useTop100Context } from 'common/contexts/top100Context';

import { Typography } from 'common/components/Typography';
import s from './styles.css';

interface IHumanDesignStructure {
  isMobile?: boolean
}

export function HumanDesignStructure({ isMobile }: IHumanDesignStructure) {
  const visibleBlockRef = useRef<HTMLParagraphElement>(null);

  const [visibleText, setVisibleText] = useState(false);
  const [visibleHeight, setVisibleHeight] = useState<number | null>(null);

  const {
    top100Prefix,
  } = useTop100Context();

  const top100 = {
    [`data-horo-${
      isMobile ? 'mobile-new' : 'new'
    }`]: `${top100Prefix}::visible_button::${visibleText ? 'visible' : 'hide'}`,
  };

  useEffect(() => {
    if (visibleBlockRef?.current) {
      setVisibleHeight(visibleBlockRef.current.offsetHeight);
    }
  }, []);

  return (
    <div className={cn(
      s.root,
      visibleText && s.visible,
    )}
    >
      <h2 className={cn(s.title, isMobile ? s.titleMobile : s.titleDesktop)}>
        Из чего состоит система Дизайн Человека
      </h2>
      <div
        className={s.collapseText}
        style={{
          height: visibleHeight ? `${visibleHeight}px` : 'auto',
        }}
      >
        <p
          ref={visibleBlockRef}
        >
          Бодиграф отражает схему вашей личности, осознаваемые и неосознаваемые особенности.
          <br />
          Рассчитав свой бодиграф, вы увидите, что какие-то центры закрашены, а&nbsp;какие-то – нет.
          Если центр закрашен, значит, эти сферы вашей жизни устойчивы.
        </p>
        <p>
          А в пустых центрах сосредоточены все наши волнения, страхи, вся неуверенность.
          Суть в том, чтобы сконцентрироваться на том, что есть, и перестать беспокоиться
          о том, чего нет.
        </p>
        <ul>
          <li>
            Сакральный центр — обеспечивает нас постоянным притоком жизненной силы.
          </li>
          <li>
            Центр солнечного сплетения — это наши эмоции.
            Если центр закрашен, то наши эмоции — действительно наши,
            а вот если он пуст — мы лишь воспринимаем и усиливаем то, что чувствуют другие.
          </li>
          <li>
            Корневой центр наделяет нас силой выживания, заставляет проживать стресс и расти,
            преодолевая себя. Центр Эго делает нас волевыми и способными давать обещания и
            выполнять их.
          </li>
          <li>
            Центр Джи наполняет любовью и указывает направление, в котором нужно двигаться.
            Центр Селезенки — те, у кого он заполнен, обладают этаким «нюхом», интуитивно понимая,
            что съедобно, а что нет — в самом широком смысле.
          </li>
          <li>
            Теменной и Аджна центры — это то, что находится в голове: «заполненная» голова дает
            определенный образ мышления, «пустая» голова отличается гибкостью мышления.
          </li>
          <li>
            Горловой центр — в закрашенном варианте заставляет нас выражать себя словом или
            действием, при «пустом» горле мы говорим или делаем то, что хотели бы сказать
            или сделать другие.
          </li>
        </ul>
        <p>
          Таким образом, Дизайн Человека показывает нам, что в нас наше, а что — чужое, неродное.
          И в зависимости от того, какие центры закрашены, а какие нет (и не только), выделяют
          4 типа людей.
        </p>
      </div>
      <Typography
        variant="defaultRegular"
        className={s.readMore}
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        element={<button type="button" onClick={() => setVisibleText(!visibleText)} />}
        {...top100}
      >
        {visibleText ? 'Свернуть' : 'Читать дальше'}
        <Icon id="arrow-down" className={cn(s.readMoreIcon, visibleText && s.readMoreIconUp)} />
      </Typography>
    </div>
  );
}

HumanDesignStructure.defaultProps = {
  isMobile: false,
};
