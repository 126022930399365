import { useEffect, useState } from 'react';
import _throttle from 'lodash.throttle';
import { isInVerticalViewport } from 'common/utils/isInViewport';

const useIsInViewport = (ref: React.RefObject<HTMLElement>) => {
  const [inViewport, setInViewport] = useState(false);

  const check = _throttle(() => {
    if (ref && ref.current) {
      setInViewport(isInVerticalViewport(ref).top);
    }
  }, 100);

  useEffect(() => {
    if (ref && ref.current) {
      check();

      window.addEventListener('scroll', check);
    }

    return () => window.removeEventListener('scroll', check);
  }, [ref]); // eslint-disable-line react-hooks/exhaustive-deps

  return inViewport;
};

export default useIsInViewport;
