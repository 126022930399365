import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import Popup from '@rambler-components/popup';
import Button from '@rambler-components/button';
import Image from '@rambler-components/image';

import { useTop100Context } from 'common/contexts/top100Context';
import { getTop100Markup } from 'common/utils/getTop100Markup';

import { Icon } from 'common/components/Icon';

import s from './index.css';

interface IPreviewPopup {
  preview: any
  isOpen: boolean
  isSelected: boolean
  onClose: () => void
  onSelect: (selectId: string) => void
}

function PreviewPopup({
  preview = {},
  isOpen,
  isSelected,
  onClose,
  onSelect,
}: IPreviewPopup) {
  const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);

  const {
    top100Prefix,
  } = useTop100Context();

  const { title, image } = preview;

  const previewImg = image?.[`${isMobile ? 'mobile' : 'desktop'}`];

  const handleSelect = () => {
    onSelect(preview.id);
    onClose();
  };

  return (
    <Popup
      className={s.wrapper}
      isFullPage={isMobile}
      withCloseButton={false}
      width={600}
      zIndex={5005}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={cn(s.content, isMobile && s.mobile)}>
        {isMobile && (
          <Button
            type={isMobile ? 'white' : 'secondary'}
            className={s.closeButton}
            onClick={onClose}
            {...getTop100Markup(isMobile, top100Prefix, `subscribe_preview_popup::${preview.id}::close_button`)}
          >
            <Icon id="close" className={s.icon} />
          </Button>
        )}
        <div className={s.header}>
          {!isMobile && <h3 className={s.title}>{title}</h3>}
          <Button
            type={isSelected ? 'secondary' : 'primary'}
            onClick={handleSelect}
            className={s.selectButton}
            {...getTop100Markup(isMobile, top100Prefix, `subscribe_preview_popup::${preview.id}::select_button`)}
          >
            {isSelected ? 'Выбрано' : 'Выбрать эту рассылку'}
          </Button>
          {!isMobile && (
            <Button
              type={isMobile ? 'white' : 'secondary'}
              className={s.closeButton}
              onClick={onClose}
              {...getTop100Markup(isMobile, top100Prefix, `subscribe_preview_popup::${preview.id}::close_button`)}
            >
              <Icon id="close" className={s.icon} />
            </Button>
          )}
        </div>
        <div className={s.preview}>
          {previewImg && (
            <Image
              className={s.previewImg}
              alt={title}
              isS3={false}
              isImg
              src={previewImg.x1}
              src2x={previewImg.x2}
            />
          )}
        </div>
      </div>
    </Popup>
  );
}

export default PreviewPopup;
