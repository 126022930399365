import { createElement, cloneElement } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import s from './styles.css';

type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'largeMedium'
  | 'defaultBold'
  | 'defaultSemiBold'
  | 'defaultMedium'
  | 'defaultRegular'
  | 'smallExtraBold'
  | 'smallBold'
  | 'smallMedium'
  | 'xSmallMedium';

export type TypographyType = {
  children?: React.ReactNode
  variant: TypographyVariant
  className?: string
  component?: 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'div' | 'li'
  element?: JSX.Element
};

const variantTagMapper: {
  [x in TypographyVariant]: keyof JSX.IntrinsicElements
} = {
  h1:              'h1',
  h2:              'h2',
  h3:              'h3',
  largeMedium:     'span',
  defaultBold:     'span',
  defaultSemiBold: 'span',
  defaultMedium:   'span',
  defaultRegular:  'span',
  smallExtraBold:  'span',
  smallBold:       'span',
  smallMedium:     'span',
  xSmallMedium:    'span',
};

function Typography({
  children,
  variant = 'defaultMedium',
  className = '',
  component,
  element,
  ...rest
}: TypographyType) {
  const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);

  const textClassName = cn(
    s[variant],
    isMobile && s[`${variant}Mobile`],
    className,
  );

  if (element) {
    return cloneElement(
      element,
      { className: textClassName, ...rest },
      children,
    );
  }

  return createElement(
    component || variantTagMapper[variant] || 'span',
    {
      className: textClassName,
      ...rest,
    },
    children,
  );
}

Typography.defaultProps = {
  children:  undefined,
  component: undefined,
  className: '',
  element:   undefined,
};

export { Typography };
