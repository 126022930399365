import React from 'react';
import cn from 'classnames';

import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
import getDate from 'date-fns/getDate';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';

import { Typography } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import {
  HUMAN_DESIGN_PRICE,
  HUMAN_DESIGN_PARTNER,
  // HUMAN_DESIGN_PARTNER_TEXT,
  // HUMAN_DESIGN_PARTNER_TAGS,
} from 'config/constants/humanDesign';

import s from './styles.css';

interface IHumanDesignPartnerResult {
  isMobile: boolean
  humanDesignDate?: string
  place?: number
  className?: string
}

const getPartnerParams = (date?: string, place?: number) => {
  if (!date || !place) return null;

  const currentDate = new Date(date);
  const year = getYear(currentDate);
  const month = getMonth(currentDate) + 1;
  const day = getDate(currentDate);
  const hours = getHours(currentDate);
  const minutes = getMinutes(currentDate);
  const dateStr = `year=${year}&month=${month}&day=${day}&hours=${hours}&minutes=${minutes}`;

  let placeStr = '';
  if (place) {
    placeStr = `placeId=${place}`;
  }

  return `?${dateStr}&${placeStr}`;
};

function HumanDesignPartnerResult({
  isMobile,
  humanDesignDate,
  place,
  className,
}: IHumanDesignPartnerResult) {
  const {
    top100Prefix,
  } = useTop100Context();

  const renderButton = (position: string) => (
    <Typography
      variant="defaultBold"
      className={cn(s.buttonPartner, s[`buttonPartner-${position}`])}
      element={(
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a
          href={`${HUMAN_DESIGN_PARTNER}${getPartnerParams(humanDesignDate, place)}`}
          target="_blank"
          rel="noreferrer"
          {...getTop100Markup(isMobile, top100Prefix, `partner_button::${position}`)}
        />
      )}
    >
      {`Расширенное прочтение за ${HUMAN_DESIGN_PRICE} Р`}
    </Typography>
  );

  return (
    <div className={cn(s.partnerResult, className)}>
      {renderButton('top')}
      {/* <Typography
        variant={isMobile ? 'h3' : 'h2'}
        component="h2"
        className={s.title}
      >
        Расширенное прочтение
      </Typography>
      <Typography
        variant="defaultRegular"
        className={s.partnerText}
      >
        {HUMAN_DESIGN_PARTNER_TEXT}
      </Typography>
      <Typography
        variant="defaultMedium"
        component="div"
        className={s.partnerTags}
      >
        {HUMAN_DESIGN_PARTNER_TAGS.map(tag => (
          <span className={s.partnerTag} key={tag}>{tag}</span>
        ))}
      </Typography>
      {renderButton('bottom')} */}
    </div>
  );
}

HumanDesignPartnerResult.defaultProps = {
  className:       '',
  humanDesignDate: '',
  place:           null,
};

export { HumanDesignPartnerResult };
