let isFreezed = false;
const originStyle: Record<string, string> = {};

// кому то может показаться, что есть способ лучше
// и окажется прав. Но тот способ некорректно работает
// в мобильных браузерах, потому что там есть свой заголовок
// который при скролле прячется и все портит
// PS - позаимствовано из /rambler-ui/components
export const disableBodyScroll = (bool: boolean) => {
  if (typeof window === 'undefined' || typeof document.body === 'undefined') {
    return;
  }

  const widthScrollbar = window.innerWidth - document.documentElement.clientWidth;
  const { style: documentStyle } = document.documentElement;
  const { top, left } = document.documentElement.getBoundingClientRect();

  if (bool) {
    if (!isFreezed) {
      originStyle.position = documentStyle.position || '';
      originStyle.overscrollBehavior = documentStyle.overscrollBehavior || '';
      originStyle.width = documentStyle.width || '';
      originStyle.height = documentStyle.height || '';
      originStyle.top = documentStyle.top || '';
      originStyle.left = documentStyle.left || '';
      originStyle.paddingRight = documentStyle.paddingRight || '';

      Object.assign(document.documentElement.style, {
        position:           'fixed',
        overscrollBehavior: 'contain',
        width:              '100%',
        height:             '100%',
        top:                `${top}px`,
        left:               `${left}px`,
        paddingRight:       widthScrollbar ? `${widthScrollbar}px` : '',
      });

      Object.assign(document.body.style, {
        overscrollBehaviorY: 'contain',
      });

      isFreezed = true;
    }
  } else if (isFreezed) {
    Object.assign(document.documentElement.style, originStyle);
    Object.assign(document.body.style, {
      overscrollBehaviorY: 'auto',
    });
    window.scrollTo(-left, -top);

    isFreezed = false;
  }
};
