import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { Typography } from 'common/components/Typography';
import { Icon } from 'common/components/Icon';
import signNames from 'config/constants/signNames';
import YandexEvent from 'utils/counters/YandexEvent';

import { YANDEX_METRICS } from 'config/constants/counters';
import { VISIBLE_PATHS } from './constants';

import s from './styles.css';

export const VkVideoPromo: React.FC = React.memo(() => {
  const currentPath = useSelector((state: IAppState) => state.runtime.path);
  const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);
  const widgetData = useSelector((state: IAppState) => state.vkVideoWidget.data);
  const isEnable = useSelector((
    state: IAppState,
  ) => state.runtime.adminData.settings.vk_video_promo);

  const rootRef = useRef<HTMLDivElement | null>(null);
  const observerRef = useRef<IntersectionObserver>();
  const stopObserveRef = useRef(false);

  /** Отображения виджета в зависимости от настроек в админке и текущего url */
  const isVisible = VISIBLE_PATHS.includes(currentPath) ? isEnable && widgetData?.text : false;

  const sendReachGoal = (targetName: string) => {
    (new YandexEvent(YANDEX_METRICS.COMMON)).send({
      type: 'reachGoal',
      data: targetName,
    });
  };

  useEffect(() => {
    if (isVisible) {
      const callback: IntersectionObserverCallback = entries => {
        if (!stopObserveRef.current && entries[0].isIntersecting) {
          sendReachGoal('vkvideo_show');
          stopObserveRef.current = true;
          observerRef.current?.disconnect();
        }
      };

      if (rootRef.current) {
        observerRef.current = new IntersectionObserver(
          callback,
          { threshold: 0.5 },
        );
        observerRef.current.observe(rootRef.current);
      }
    }

    return () => {
      if (observerRef.current) {
        observerRef.current?.disconnect();
      }
    };
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  const title = widgetData.sign === 'all' ? 'всех знаков' : signNames.zodiacAccusative[widgetData.sign];

  const linkOnClick = () => {
    sendReachGoal('vkvideo_click');
  };

  return (
    <div
      ref={rootRef}
      className={cn(s.root, isMobile && s.mobile)}
    >
      <div className={s.content}>
        <div className={s.logo}>
          <Icon
            id="vk-video-logo-promo"
            className={s.logoIcon}
          />
        </div>
        <Typography
          variant="h3"
          className={s.link}
          element={(
            <a
              aria-label={title}
              target="_blank"
              rel="noreferrer"
              onClick={linkOnClick}
              href={`https://trk.mail.ru/c/potmp5?mt_network=sberseller&mt_medium=&mt_campaign=SberSeller_VKVI_media-natalkarta_SMART_CPM_RU_ALL_Shokina_23/09/2024_9_specialproject_&mt_creative=specialproject&mt_adset=9${widgetData?.erid ? `&erid=${widgetData?.erid}` : ''}`}
            />
          )}
        >
          Прогноз для&nbsp;
          {title}
          &nbsp;от шоу «Натальная карта»
        </Typography>
        <Typography
          variant="defaultMedium"
          component="p"
          className={s.text}
        >
          {widgetData.text}
        </Typography>
        <Typography
          variant="xSmallMedium"
          component="p"
          className={s.adInfo}
        >
          Реклама. Рекламодатель ООО&nbsp;«В Контакте», 18+. ERID:&nbsp;
          {widgetData.erid || ''}
        </Typography>
      </div>
    </div>
  );
});
