import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { ImageObject } from 'schema-dts';

import { JsonLd } from 'utils/jsond';
import { safeGet } from 'utils/safeGet';

import { cropImage } from 'common/utils/resizer';

import { getImageObject } from '../../modules';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.origin,
  ],
  origin => ({
    origin,
  }),
);

type PropsType = {
  cluster: ATCluster,
};

/**
 * Получение ImageObject schema.org для картинки кластера
 *
 * @params imgUrl - url изображения
 * @params name - длиный заголовок кластера
 * @params description - описание слайда или длинный заголовок кластера
 * @params author - название источника
 * @params isMain - указывает что слайд главный на странице
 *
 * @returns - блок schema.org описывающий ImageObject
 */
export const ClusterImageObject: React.FC<PropsType> = React.memo(({
  cluster,
}) => {
  const { origin } = useSelector(selectData);
  const { image, long_title: longTitle } = cluster;

  const url = safeGet(() => image!.url, '');
  const source = safeGet(() => image!.source, null);

  return url ? (
    <JsonLd<ImageObject>
      item={{
        '@context': 'https://schema.org',
        ...getImageObject(
          cropImage(url, origin, 1280, 720),
          longTitle || '',
          longTitle || '',
          safeGet(() => source!.title, ''),
          true,
        ),
      }}
    />
  ) : null;
});
