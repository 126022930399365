import { createContext, useContext } from 'react';

type ContextType = {
  isPwaClosed: boolean
  setIsPwaClosed: (i: boolean) => void
};

export const NotificationsContext = createContext<ContextType>({
  isPwaClosed:    false,
  setIsPwaClosed: () => {},
});

export const useNotificationsContext = () => useContext(NotificationsContext);
