import React, { useState, useRef } from 'react';
import cn from 'classnames';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';

import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import Button from '@rambler-components/button';

import { sendAccountData } from 'common/redux/account';

import { Typography } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './styles.css';

interface IHumanDesignHighlight {
  humanDesignDate?: string
  details?: HumanDesignDetailedCityType
  isMobile: boolean
  className?: string
}

const selectAccount = createSelector(
  [
    (state: IAppState) => state.account,
  ],
  account => ({
    account,
  }),
);

function HumanDesignHighlight({
  humanDesignDate,
  details,
  isMobile,
  className,
}: IHumanDesignHighlight) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const {
    top100Prefix,
  } = useTop100Context();
  const {
    account,
  }: {
    account: AccountType
  } = useSelector(selectAccount);

  const date = humanDesignDate ? new Date(humanDesignDate) : new Date();
  const placeName = details ? details.place_name : '';
  const shouldShowSave = useRef(
    isBefore(date, new Date(new Date().getFullYear() - 5, 0, 1))
    && (account.birthday !== format(date, 'yyyy-MM-dd') || account.birthtime !== format(date, 'HH:mm:ss')),
  );

  const onSave = async () => {
    if (isSaved) return;

    setIsLoading(true);

    const data: AccountType = {
      ...account,
    };
    data.birthday = format(date, 'yyyy-MM-dd');
    data.birthtime = format(date, 'HH:mm:ss');

    await dispatch(sendAccountData(data));
    setIsLoading(false);
    setIsSaved(true);
  };

  const getButtonLabel = () => {
    if (isLoading) return '';

    return isSaved ? 'Сохранено' : 'Сохранить дату рождения';
  };

  return (
    <div className={cn(
      s.root,
      isMobile ? s.rootMobile : s.rootDesktop,
      className,
    )}
    >
      <div className={cn(s.highlight, isMobile ? s.highlightMobile : s.highlightDesktop)}>
        <span>
          <Typography variant="defaultMedium" className={s.label}>Рождение</Typography>
          <Typography variant="defaultMedium" className={s.value}>{format(date, 'dd.MM.yyyy')}</Typography>
        </span>
        <span>
          <Typography variant="defaultMedium" className={s.label}>Время</Typography>
          <Typography variant="defaultMedium" className={s.value}>{format(date, 'HH:mm')}</Typography>
        </span>
        <span>
          <Typography variant="defaultMedium" className={s.label}>Город</Typography>
          <Typography variant="defaultMedium" className={s.value}>{placeName}</Typography>
        </span>
      </div>
      {shouldShowSave.current && (
        <Button
          className={cn(
            s.button,
            isMobile ? s.buttonMobile : s.buttonDesktop,
          )}
          type="secondary"
          onClick={onSave}
          isLoading={isLoading}
          disabled={isSaved}
          {...getTop100Markup(isMobile, top100Prefix, 'save_button')}
        >
          {getButtonLabel()}
        </Button>
      )}
    </div>
  );
}

HumanDesignHighlight.defaultProps = {
  humanDesignDate: '',
  details:         undefined,
  className:       '',
};

export { HumanDesignHighlight };
