import React, {
  useCallback,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { longreadTopicsList } from 'config/constants/routes';
import { selectIsTarotPage } from 'config/constants/tarot';

import { Publisher } from '../Publisher';
import { Author } from '../Author';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.path,
    (state: IAppState) => state.runtime.fullUrl,
    (state: IAppState) => state.meta,
  ],
  (
    path,
    fullUrl,
    meta,
  ) => ({
    path,
    fullUrl,
    meta,
  }),
);

function Article() {
  const {
    path,
    fullUrl,
    meta,
  } = useSelector(selectData);

  const isTarotPage = useSelector(selectIsTarotPage);

  const {
    title,
    description,
    keywords,
    og_image: ogImage,
    date,
  } = meta;

  let canonical = fullUrl.replace(/utm_\w+=\w+(&|$)/g, '');

  if (fullUrl.includes('/zodiac/general/all/today/')) {
    canonical = canonical.replace('/zodiac/general/all/today/', '/');
  }

  let identifier = path;
  const articlesRegexp = new RegExp(`${longreadTopicsList}/\\d+-.+`, 'g');
  if (path.match(articlesRegexp)) {
    const articleId = path.match(/\d+/g);
    if (articleId) {
      identifier = `horo-${articleId[0]}`;
    }
  }

  const getImageUrl = useCallback((url: string, height: string) => `${url}?img-format=auto&img-1-filter=sharpen&img-2-resize=width:1280,height:${height},fit:cover`, []);

  const imageObjects = useMemo(() => {
    if (ogImage && ogImage.length) {
      return ['1280', '960', '720'].map(height => (
        <div
          itemProp="image"
          itemScope
          itemType="https://schema.org/ImageObject"
          key={`ImageObject-${height}-${date}`}
        >
          <link itemProp="url" href={getImageUrl(ogImage, height)} />
          <link itemProp="contentUrl" href={getImageUrl(ogImage, height)} />
          <meta itemProp="width" content="1280" />
          <meta itemProp="height" content={height} />
        </div>
      ));
    }

    return null;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ogImage]);

  return (
    <>
      <meta itemProp="name" content={title} />
      <meta itemProp="about" content="Гороскопы" />

      <Publisher />
      <Author />

      {keywords && keywords.length && (
        <meta itemProp="keywords" content={keywords} />
      )}
      {description && description.length && (
        <meta itemProp="description" content={description} />
      )}

      <meta itemProp="articleSection" content={isTarotPage ? 'Таро' : 'Гороскопы'} />
      <meta itemScope itemProp="mainEntityOfPage" itemType="https://schema.org/WebPage" itemID={canonical} />
      <meta itemProp="identifier" itemType="https://schema.org/Text" content={identifier} />

      {imageObjects}

      {date && <meta itemProp="datePublished" content={date} />}
      {date && <meta itemProp="dateModified" content={date} />}
    </>
  );
}

export { Article };
