import React, { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useSwipeable, SwipeEventData } from 'react-swipeable';

import Image from '@rambler-components/image';

import { useTop100Context } from 'common/contexts/top100Context';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import YandexEvent from 'utils/counters/YandexEvent';

import { YANDEX_METRICS } from 'config/constants/counters';
import { getCards } from 'config/constants/news-letter';

import { Icon } from 'common/components/Icon';
import SuccessSubscribe from '../SubscribeSuccess';
import SubscribeForm from '../SubscribeForm';
import { disableBodyScroll } from '../utils';

import s from './index.css';

interface ISubscribePanel {
  count: number
  isVisible: boolean
  subscribeKeys: string[]
  onReset: () => void
}

function SubscribePanel({
  count,
  subscribeKeys,
  onReset,
  isVisible,
}: ISubscribePanel) {
  const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);
  const { newsLetterCrmKeys } = useSelector((state: IAppState) => state.runtime.config);

  const [mounted, setMounted] = useState(false);
  const [mobileIsOpen, setMobileIsOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);

  const {
    top100Prefix,
  } = useTop100Context();

  useEffect(() => {
    setMounted(true);

    return () => {
      disableBodyScroll(false);
    };
  }, []);

  useEffect(() => {
    if (isMobile && mounted && !isVisible) {
      disableBodyScroll(false);
    }
  }, [isMobile, mounted, isVisible]);

  const swipedUp = () => {
    if (isVisible && !mobileIsOpen) {
      setMobileIsOpen(true);
    }
  };

  const swipedDown = () => {
    if (isVisible && mobileIsOpen) {
      setMobileIsOpen(false);
      disableBodyScroll(false);
    }
  };

  const swipeStart = ({ dir }: SwipeEventData) => {
    if (dir === 'Up') {
      disableBodyScroll(true);
    }
  };

  const swipeHandlers = useSwipeable({
    delta:        20,
    onSwipedUp:   swipedUp,
    onSwipedDown: swipedDown,
    onSwipeStart: swipeStart,
    trackMouse:   true,
    trackTouch:   true,
  });

  const swipedProps = isMobile ? swipeHandlers : {};

  const mobileOpenClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    disableBodyScroll(!mobileIsOpen);
    setMobileIsOpen(open => !open);
  };

  const onSubscribe = () => {
    setSuccessOpen(true);

    const cards = getCards(newsLetterCrmKeys).filter(({ id }) => subscribeKeys.includes(id));

    if (cards.length) {
      cards.forEach(({ ymEvent }) => {
        (new YandexEvent(YANDEX_METRICS.COMMON)).send({
          type: 'reachGoal',
          data: ymEvent,
        });
      });
    }

    // для десктопа сразу брасываем состояние
    // чтобы после закрытия попапа форма не мигала из за анимации
    if (!isMobile) {
      onReset();
    }
  };

  const onCloseSucces = () => {
    setSuccessOpen(false);
    // сбрасывем состояния для мобилы в конце
    // чтобы окно успешной подписки не закрылось раньше
    if (isMobile) {
      disableBodyScroll(false);
      onReset();
    }
  };

  const onFailed = () => {
    disableBodyScroll(false);
    setMobileIsOpen(false);
    onReset();
  };

  if (!mounted) {
    return null;
  }

  return (
    <div
      className={cn(
        s.root,
        isVisible && s.visible,
        mobileIsOpen && s.mobileOpen,
        isMobile && s.mobile,
      )}
      {...swipedProps}
    >
      {!successOpen && (
        <div className={s.content}>
          {isMobile && mobileIsOpen && (
            <div className={s.illustration}>
              <div className={s.wave} />
              <div className={s.subscription}>
                <Image
                  className={s.imageIcon}
                  isS3={false}
                  isImg
                  src="/news-letter/subscription.png"
                  src2x="/news-letter/subscription@2x.png"
                />
              </div>
            </div>
          )}
          <div className={s.info}>
            {!isMobile && (
              <>
                <p className={s.countTitle}>
                  Рассылок выбрано:&nbsp;
                  {count}
                </p>
                <p className={s.countInfo}>
                  Введите свои данные, и на вашу почту придёт первое письмо
                </p>
              </>
            )}
            {isMobile && (
              <button
                type="button"
                aria-label="Кнопка открытия формы"
                className={s.toggleButton}
                onClick={mobileOpenClick}
                {...getTop100Markup(isMobile, top100Prefix, `subscribe_panel::open_button${mobileIsOpen ? '::open_full' : ''}`)}
              >
                <div className={s.countTitle}>
                  Рассылок выбрано:&nbsp;
                  {count}
                </div>
                <Icon id="arrow-down-m" className={s.icon} />
              </button>
            )}
          </div>
          <div className={s.form}>
            <div className={s.formContent}>
              <SubscribeForm
                isVisible={isMobile ? mobileIsOpen : isVisible}
                subscribeKeys={subscribeKeys}
                isShortMobile={isMobile && !mobileIsOpen}
                direction={isMobile ? 'column' : 'row'}
                inputType={isMobile ? 'fill' : 'border'}
                onSuccess={onSubscribe}
                onFailed={onFailed}
              />
            </div>
            <p className={s.agreement}>
              Нажимая на кнопку «Подписаться» вы соглашаетесь с условиями&nbsp;
              <a
                target="_blank"
                rel="noreferrer"
                href="https://help.rambler.ru/www/legal/1397/"
                {...getTop100Markup(isMobile, top100Prefix, 'subscribe_panel::agreement_link')}
              >
                пользовательского соглашения
              </a>
            </p>
          </div>
        </div>
      )}
      <SuccessSubscribe
        isOpen={successOpen}
        isOnlyContent={isMobile}
        onClose={onCloseSucces}
      />
    </div>
  );
}

export default memo(SubscribePanel);
