import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { useTop100Context } from 'common/contexts/top100Context';
import { getTop100Markup } from 'common/utils/getTop100Markup';

import Button from '@rambler-components/button';

import { Icon } from 'common/components/Icon';

import s from './index.css';

interface IContent {
  withCloseButton?: boolean
  onClose: () => void
}

function Content({ withCloseButton, onClose }: IContent) {
  const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);

  const {
    top100Prefix,
  } = useTop100Context();

  return (
    <div className={cn(s.root, isMobile && s.mobile)}>
      {isMobile && withCloseButton && (
        <Button
          className={s.closeButton}
          type="white"
          onClick={onClose}
          {...getTop100Markup(isMobile, top100Prefix, 'success::button_close')}
        >
          <Icon id="close" className={s.closeIcon} />
        </Button>
      )}
      <Icon id="subscription" className={s.icon} />
      <h3 className={s.title}>Вы подписались на рассылку</h3>
      <p className={s.text}>
        На вашу почту уже летит приветственное
        <br />
        письмо
      </p>
      <Button
        href="/"
        className={s.button}
        onClick={onClose}
        {...getTop100Markup(isMobile, top100Prefix, 'success_popup::link_main')}
      >
        На главную
      </Button>
    </div>
  );
}

Content.defaultProps = {
  withCloseButton: false,
};

export default Content;
