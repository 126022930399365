import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { FeedbackForm } from 'common/components/FeedbackForm';
import PwaMobileNotification from 'mobile/components/PwaNotification';
import { selectIsTarotPage } from 'config/constants/tarot';

import { NotificationsContext } from './context/notificationsContext';

import { Pwa } from './components/Pwa';
import { PushSubscribe } from './components/PushSubscribe';

const selectRuntimeData = createSelector(
  [
    (state: IAppState) => state.runtime.PWADisplayMode,
    (state: IAppState) => state.runtime.reloadKey,
    (state: IAppState) => state.runtime.isMobile,
    (state: IAppState) => state.runtime.splits.horo_pwa_install_split,
    (state: IAppState) => selectIsTarotPage(state),
  ],
  (
    PWADisplayMode,
    reloadKey,
    isMobile,
    installSplit,
    isTarotPage,
  ) => ({
    PWADisplayMode,
    reloadKey,
    isMobile,
    installSplit,
    isTarotPage,
  }),
);

// * - при условии того, что пользователь не очистит кеш браузера и не переполнит localstorage

function Notifications() {
  const {
    PWADisplayMode,
    reloadKey,
    isMobile,
    installSplit,
    isTarotPage,
  } = useSelector(selectRuntimeData);
  const [isPwaClosed, setIsPwaClosed] = useState(false);
  const [isShowPushSubscribe, setIsShowPushSubscribe] = useState(false);
  const pwaClosedReloadKey = useRef<number | undefined>(undefined);
  const [needShowPwaNotification, setNeedShowPwaNotification] = useState(false);

  useEffect(() => {
    setNeedShowPwaNotification(PWADisplayMode === 'browser');
  }, [PWADisplayMode]);

  useEffect(() => {
    if (isPwaClosed && !isShowPushSubscribe) {
      if (!pwaClosedReloadKey.current) {
        pwaClosedReloadKey.current = reloadKey;
      }

      if (pwaClosedReloadKey.current && pwaClosedReloadKey.current + 1 === reloadKey) {
        setIsShowPushSubscribe(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPwaClosed, reloadKey, isShowPushSubscribe]);

  const contextMemo = useMemo(() => ({ isPwaClosed, setIsPwaClosed }), [isPwaClosed]);

  const pwaComponent = useMemo(() => {
    /**
     * На время сплита для мобилы используем другой компонент
     * На мобильной странице таро фидбэк отображаем в формате карточки
     * Поэтому здесь не показываем
     */
    if (isMobile && !isTarotPage) {
      if (installSplit === 72) {
        return <PwaMobileNotification variant="float" />;
      }

      return <FeedbackForm />;
    }

    if (needShowPwaNotification) {
      return <Pwa />;
    }

    return null;
  }, [isMobile, installSplit, needShowPwaNotification, isTarotPage]);

  return (
    <NotificationsContext.Provider value={contextMemo}>
      {pwaComponent}
      {isShowPushSubscribe && <PushSubscribe />}
    </NotificationsContext.Provider>
  );
}

export { Notifications };
