import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import _throttle from 'lodash.throttle';

import { safeGet } from 'utils/safeGet';

import { fetchTailData } from 'common/redux/pages';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.nextPage,
    (state: IAppState) => state.runtime.hasNextPage,
    (state: IAppState) => state.runtime.currentPage,
    (state: IAppState) => state.runtime.currentSection,
    (state: IAppState) => state.runtime.currentBurgerSection,
    (state: IAppState) => state.runtime.reloadKey,
  ],
  (
    nextPage,
    hasNextPage,
    currentPage,
    currentSection,
    currentBurgerSection,
    reloadKey,
  ) => ({
    nextPage,
    hasNextPage,
    currentPage,
    currentSection,
    currentBurgerSection,
    reloadKey,
  }),
);

const selectPage = createSelector(
  (
    state: IAppState,
    currentPage: RuntimeType['currentPage'],
    currentSection: RuntimeType['currentSection'],
  ) => state.pages[currentPage][currentSection],
  page => ({
    page,
  }),
);

/** Хук, осуществляющий подгрузку карточек по скроллу
 *
 * @param fetchPointRef - точка отсчета при расчётах расстояния до момента загрузки
 * @param heightBeforeFetchNextCards - высота, на которую смещается место загрузки вверх
 */
export const useFetchNextPage = (
  fetchPointRef: React.MutableRefObject<any>,
  heightBeforeFetchNextCards: number,
): void => {
  const dispatch = useDispatch();
  const {
    nextPage,
    hasNextPage,
    currentPage,
    currentSection,
    currentBurgerSection,
    reloadKey,
  } = useSelector(selectData);
  const { page } = useSelector(state => selectPage(state, currentPage, currentSection));
  const pagesData = safeGet(() => page, undefined);
  const nextESPageIndex = useRef(0);
  const fetchBlocked = useRef(false);

  // Сброс при пагинации
  useEffect(() => {
    nextESPageIndex.current = 0;
  }, [reloadKey]);

  useEffect(() => {
    const fetchNextPage = async () => {
      const rect = fetchPointRef && fetchPointRef.current
        ? fetchPointRef.current.getBoundingClientRect()
        : null;
      const screenHeight = window.innerHeight || document.documentElement.clientHeight;

      if (
        rect
        && rect.top - screenHeight <= heightBeforeFetchNextCards
        && hasNextPage
        && !fetchBlocked.current
      ) {
        fetchBlocked.current = true;

        if (pagesData && currentBurgerSection !== 'articles-topic-yoga-articles') {
          await dispatch(fetchTailData(`&page=${nextPage}`));
        }

        fetchBlocked.current = false;
      }
    };

    const throttledChecker = _throttle(fetchNextPage, 33, {
      trailing: true,
    });

    throttledChecker();

    window.addEventListener('scroll', throttledChecker);

    return () => {
      window.removeEventListener('scroll', throttledChecker);
    };
  /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    nextPage,
    hasNextPage,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */
};
