import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { Ad } from 'common/components/Ad';

import { useRcmWidget } from 'common/hooks/useRcmWidget';

import s from './styles.css';

type PropsType = {
  className?: string;
};

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.currentPage,
    (state: IAppState) => state.runtime.currentSection,
    (state: IAppState) => state.runtime.isMobile,
  ],
  (
    currentPage,
    currentSection,
    isMobile,
  ) => ({
    currentPage,
    currentSection,
    isMobile,
  }),
);

const selectPage = createSelector(
  (
    state: IAppState,
    currentPage: RuntimeType['currentPage'],
    currentSection: RuntimeType['currentSection'],
  ) => state.pages[currentPage][currentSection],
  page => ({
    page,
  }),
);

/**
 * Виджет реккомендов.
 *
 * @param className - класс, если нужна обертка вокруг виджета;
 */
export const RcmWidget: React.FC<PropsType> = React.memo(({
  className,
}) => {
  const {
    currentPage,
    currentSection,
    isMobile,
  } = useSelector(selectData);
  const { page } = useSelector(state => selectPage(state, currentPage, currentSection));

  const nodeId = useMemo(
    () => `rcmw-container-${currentPage}-${currentSection}`,
    [currentPage, currentSection],
  );

  const clusterId = page.content?.article?.id
    ? `${page.content.article.id}`
    : undefined;

  const rcmwContainerRefFirst = useRcmWidget({
    nodeId,
    clusterId,
  });

  return (
    <div className={cn(s.root, isMobile ? s.mobile : s.desktop, className)}>
      {isMobile && (
        <Ad
          name="listing_spec"
          wrapperClassName={s.adMobile}
        />
      )}

      <div id={nodeId} ref={rcmwContainerRefFirst as any} />

      {!isMobile && (
        <Ad
          name="native3"
          wrapperClassName={s.adDesktop}
        />
      )}
    </div>
  );
});

RcmWidget.defaultProps = {
  className: '',
};
