/* eslint-disable no-console */

import { captureException } from '@sentry/react';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { RCM_ID } from 'config/constants/counters';
import { createSelector } from 'reselect';

/**
 * Добавление функции в очередь виджета рекомендов
 *
 * @param callback - добавляемая функция
 */
const addCommand = (callback: () => void) => {
  window.rcmw.cmd.push(callback);
};

type UseRcmWidgetType = ({
  nodeId,
  clusterId,
}: {
  nodeId: string;
  clusterId?: string;
}) => React.RefObject<Rcmw.IInitBlockResult | null>;

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.isMobile,
    (state: IAppState) => state.runtime.ruid,
    (state: IAppState) => state.runtime.userId,
    (state: IAppState) => state.runtime.puid6,
    (state: IAppState) => state.runtime.puid15,
    (state: IAppState) => state.runtime.puid18,
    (state: IAppState) => state.runtime.puid48,
  ],
  (
    isMobile,
    ruid,
    userId,
    puid6,
    puid15,
    puid18,
    puid48,
  ) => ({
    isMobile,
    ruid,
    userId,
    puid6,
    puid15,
    puid18,
    puid48,
  }),
);

/**
 * Хук инициализации виджета рекомендов
 * @see https://recsys.pages.rambler-co.ru/recommender/docs/index.html#/widget/integration
 *
 * @param nodeId - id DOM-элемента, внутри которого будет отрендерен виджет;
 * @param clusterId - id кластера, для которого получаем рекомменды;
 */
export const useRcmWidget: UseRcmWidgetType = ({
  nodeId,
  clusterId,
}) => {
  const {
    isMobile,
    ruid,
    userId,
    puid6,
    puid15,
    puid18,
    puid48,
  } = useSelector(selectData);

  const rcmBlockRef = useRef<Rcmw.IInitBlockResult | null>(null);

  useEffect(() => {
    if (!window.rcmw) {
      // Добавление скрипта
      window.rcmw = window.rcmw || { cmd: [] };

      const script = document.createElement('script');

      script.async = true;
      script.src = 'https://rcmjs.rambler.ru/static/rcmw/rcmw.js';

      document.head.appendChild(script);
    }

    const templateName = (isMobile ? 'v3' : 'v4') as Rcmw.Template;
    const layoutType = (isMobile ? 'horizontal' : 'vertical') as Rcmw.WidgetLayoutType;

    const settings: Rcmw.IUserBlockSettings = {
      rcmId:         RCM_ID,
      blockId:       isMobile ? '3563fccf4f24411fabfe6001dc01c48d' : '66c74149b1394a558b23d86a4c98a598',
      templateName,
      layoutType,
      nodeId,
      userId,
      xuid:          ruid,
      contextItemId: clusterId || null,
      ads:           {
        enabled: true,
        jparams: {
          puid6,
          puid15,
          puid18,
          puid48,
        },
      },
    };

    // Инициализация блока
    addCommand(async () => {
      try {
        const block = await window.rcmw.initBlock(settings);
        rcmBlockRef.current = block;
      } catch (error) {
        console.log('🚀 ~ addCommand ~ error:', error);
        captureException(error);
      }
    });

    return () => {
      if (!rcmBlockRef.current) return;

      // Удаление блока
      addCommand(() => {
        window.rcmw.createSessionId();

        if (rcmBlockRef.current) {
          window.rcmw.removeBlock(rcmBlockRef.current);
        }

        rcmBlockRef.current = null;
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeId, userId, ruid, clusterId]);

  return rcmBlockRef;
};
