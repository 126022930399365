import React, { useMemo, useCallback } from 'react';
import cn from 'classnames';

import { Link } from 'react-router-dom';

import { Image } from 'common/components/Card/Image';
import { Ad } from 'common/components/Ad';
import { Typography } from 'common/components/Typography';

import { getShortDescription } from 'common/utils/hdShortDescription';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './styles.css';

interface IHumanDesignProfiles {
  profiles?: HumanDesignType['profiles']
  isMobile: boolean
  ads?: {
    [x: number]: string
  }
  limit: number
  className?: string
}

function HumanDesignProfiles({
  profiles,
  isMobile,
  ads,
  limit,
  className,
}: IHumanDesignProfiles) {
  const {
    top100Prefix,
  } = useTop100Context();

  const celebritiesLimit = isMobile ? 1 : 2;
  const limitedCelebrities = useCallback(
    celebrities => celebrities.slice(0, celebritiesLimit),
    [celebritiesLimit],
  );
  const list = useMemo(() => (profiles ? profiles.slice(0, limit) : []), [profiles, limit]);

  const img = useCallback((profile: HumanDesignProfileType) => (
    <div className={cn(
      s.profileImageContainer,
      !isMobile ? s.profileImageContainerDesktop : s.profileImageContainerMobile,
    )}
    >
      <Image
        wrapperClassName={cn(
          s.profileImage,
          isMobile ? s.profileImageMobile : s.profileImageDesktop,
        )}
        image={{
          url: profile.image.url,
          s3:  true,
        }}
        height={isMobile ? 160 : 140}
        width={isMobile ? 340 : 170}
        title={profile.title}
        isLazy={false}
        isMobile={isMobile}
      />
    </div>
  ), [isMobile]);

  if (!list || !list.length) return null;

  return (
    <div className={cn(
      s.root,
      isMobile ? s.rootMobile : s.rootDesktop,
      className,
    )}
    >
      <Typography
        variant="h2"
        className={isMobile ? s.titleMobile : ''}
      >
        Профили в Дизайне человека
      </Typography>
      <div className={cn(s.list, isMobile ? s.listMobile : s.listDesktop)}>
        {list.map((profile, index) => {
          const key = `HumanDesignprofiles-${profile.slug}`;
          const { celebrities } = profile;

          return (
            <div key={key}>
              <Link
                className={cn(
                  s.profile,
                  isMobile ? s.profileMobile : s.profileDesktop,
                  index === list.length - 1 && s.profileLast,
                )}
                to={`/dizain-cheloveka/profili/${profile.slug}/`}
                {...getTop100Markup(isMobile, top100Prefix, `interlinked_block::profile::${profile.slug}`)}
              >
                {!isMobile && img(profile)}
                <div className={s.profileText}>
                  <Typography
                    variant="h3"
                    component="div"
                    className={cn(
                      s.profileTitle,
                      isMobile ? s.profileTitleMobile : s.profileTitleDesktop,
                    )}
                  >
                    {profile.slug.replace('-', '/')}
                    {' '}
                    {profile.title}
                  </Typography>
                  {isMobile && img(profile)}
                  <Typography
                    variant="defaultMedium"
                    component="div"
                    className={cn(
                      s.profileDescription,
                      isMobile ? s.profileDescriptionMobile : s.profileDescriptionDesktop,
                    )}
                  >
                    {getShortDescription(profile.short_description)}
                  </Typography>
                  <Typography
                    variant="defaultMedium"
                    component="div"
                    className={cn(s.profileCelebs, isMobile && s.profileCelebsMobile)}
                  >
                    {limitedCelebrities(celebrities)
                      .map((celebrity: HumanDesignCelebrityType, celebIndex: number) => (
                        <span key={celebrity.name}>
                          {celebrity.name}
                          {celebIndex === celebritiesLimit - 1 ? ' и другие' : ', '}
                        </span>
                      ))}
                  </Typography>
                </div>
              </Link>
              {ads && ads[index] && (
                <Ad
                  name={ads[index]}
                  wrapperClassName={isMobile ? s.adMobile : s.ad}
                />
              )}
            </div>
          );
        })}
      </div>
      <Typography
        variant="defaultBold"
        component="div"
        className={cn(s.buttonContainer, isMobile && s.buttonContainerMobile)}
      >
        <Link
          className={cn(s.button, isMobile ? s.buttonMobile : s.buttonDesktop)}
          to="/dizain-cheloveka/profili/"
          {...getTop100Markup(isMobile, top100Prefix, 'interlinked_block::profile::button_all_profiles')}
        >
          Смотреть все профили
        </Link>
      </Typography>
    </div>
  );
}

HumanDesignProfiles.defaultProps = {
  profiles:  undefined,
  className: '',
  ads:       {},
};

export { HumanDesignProfiles };
