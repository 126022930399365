export const VISIBLE_PATHS = [
  '/',
  '/aries/',
  '/taurus/',
  '/gemini/',
  '/cancer/',
  '/leo/',
  '/virgo/',
  '/libra/',
  '/scorpio/',
  '/sagittarius/',
  '/capricorn/',
  '/aquarius/',
  '/pisces/',
  '/zodiac/general/all/today/',
];
