import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { Icon } from 'common/components/Icon';
import { getTop100Markup } from 'common/utils/getTop100Markup';

import s from './styles.css';

interface IMenuItemProps {
  title: string;
  items: { id: string; name: string; slug: string }[];
  slug: string,
  isCompact: boolean;
  isMobile: boolean;
  top100Prefix: string;
  isExpanded: boolean;
  setExpandedSlug: React.Dispatch<React.SetStateAction<string>>;
}

const MenuItem: React.FC<IMenuItemProps> = React.memo(
  ({
    items, title, slug, isCompact, isMobile, top100Prefix, isExpanded, setExpandedSlug,
  }) => {
    const { pathname } = useLocation();

    const hasActiveItem = items.some(
      ({ slug: itemSlug }) => pathname.includes(`/taro/znachenie-kart/${itemSlug}/`),
    );

    const isActiveGroup = pathname.includes(`/taro/znachenie-kart/${slug}/`);

    useEffect(() => {
      if (hasActiveItem || isActiveGroup) {
        setExpandedSlug(slug);
      }
    }, [hasActiveItem, isActiveGroup, setExpandedSlug, slug]);

    const toggleMenu = () => {
      setExpandedSlug(prevSlug => (prevSlug === slug ? '' : slug));
    };

    return (
      <div>
        <button
          type="button"
          onClick={toggleMenu}
          className={cn(
            s.button,
            isExpanded && s.buttonExpanded,
            isCompact && s.buttonCompact,
            isActiveGroup && s.buttonActive,
          )}
          {...getTop100Markup(isMobile, top100Prefix, isExpanded ? 'suit_close' : 'suit_open')}
        >
          <Icon
            id={isCompact ? 'arrow-next-small' : 'arrow-next'}
            className={s.icon}
          />
          {title}
        </button>

        <ul
          className={cn(
            s.list,
            isExpanded && s.listVisible,
            isCompact && s.listCompact,
          )}
        >
          {items.map(({ name, id, slug: itemSlug }) => {
            const href = `/taro/znachenie-kart/${itemSlug}/`;
            const isActiveItem = pathname.includes(href);

            return (
              <li key={id} className={s.listItem}>
                <Link
                  to={href}
                  className={cn(
                    s.itemLink,
                    isActiveItem && s.itemLinkActive,
                    isCompact && s.itemLinkCompact,
                  )}
                  {...getTop100Markup(isMobile, top100Prefix, 'card_click')}
                >
                  {name}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  },
);

export { MenuItem };
