import React from 'react';

import Hint from '@rambler-components/hint';

import { Icon } from 'common/components/Icon';

import s from './styles.css';

type FieldHintType = {
  type: 'birthday' | 'birthtime' | 'gender'
};

export const FieldHint: React.FC<FieldHintType> = React.memo(({ type }) => {
  if (type === 'birthday') {
    return (
      <Hint className={s.hint}>
        <div>
          Дата рождения важна
          <br />
          <span className={s.nowrap}>
            для&nbsp;
            <Icon id="horo-logo" className={s.icon} />
            &nbsp;Гороскопов,&nbsp;
            <Icon id="biorhythms-logo" className={s.icon} />
            &nbsp;Картины&nbsp;дня,&nbsp;
          </span>
          <br />
          <span className={s.nowrap}>
            <Icon id="human-design-logo" className={s.icon} />
            &nbsp;Дизайна&nbsp;человека,&nbsp;и&nbsp;
            <Icon id="tarot-logo" className={s.icon} />
            &nbsp;Таро&nbsp;
          </span>
        </div>
      </Hint>
    );
  }

  if (type === 'birthtime') {
    return (
      <Hint className={s.hint}>
        Время рождения важно
        <br />
        <span className={s.nowrap}>
          для&nbsp;
          <Icon id="human-design-logo" className={s.icon} />
          &nbsp;Дизайна человека
        </span>
      </Hint>
    );
  }

  if (type === 'gender') {
    return (
      <Hint className={s.hint}>
        <span className={s.nowrap}>
          Пол важен для&nbsp;
          <Icon id="horo-logo" className={s.icon} />
          &nbsp;Гороскопов
        </span>
      </Hint>
    );
  }

  return null;
});
