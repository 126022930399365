import React, { useMemo, useCallback } from 'react';
import cn from 'classnames';

import { Link } from 'react-router-dom';

import { Image } from 'common/components/Card/Image';
import { Ad } from 'common/components/Ad';
import { Typography } from 'common/components/Typography';

import { getShortDescription } from 'common/utils/hdShortDescription';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './styles.css';

interface IHumanDesignTypes {
  types?: HumanDesignType['types']
  limit: number
  isMobile: boolean
  isFullList?: boolean
  ads?: {
    [x: number]: string
  }
  className?: string
}

function HumanDesignTypes({
  types,
  isMobile,
  isFullList,
  ads,
  limit,
  className,
}: IHumanDesignTypes) {
  const {
    top100Prefix,
  } = useTop100Context();

  const celebritiesLimit = isMobile ? 1 : 2;
  const limitedCelebrities = useCallback(
    celebrities => celebrities.slice(0, celebritiesLimit),
    [celebritiesLimit],
  );
  const list = useMemo(
    () => (isFullList ? types : types && types.slice(0, limit)),
    [types, isFullList, limit],
  );

  const img = useCallback((type: HumanDesignTypeType) => (
    <div className={cn(
      s.typeImageContainer,
      !isMobile ? s.typeImageContainerDesktop : s.typeImageContainerMobile,
    )}
    >
      <Image
        wrapperClassName={cn(
          s.typeImage,
          isMobile ? s.typeImageMobile : s.typeImageDesktop,
        )}
        image={{
          url: type.image.url,
          s3:  true,
        }}
        height={isMobile ? 160 : 140}
        width={isMobile ? 340 : 170}
        title={type.title}
        isLazy={false}
        isMobile={isMobile}
      />
    </div>
  ), [isMobile]);

  if (!list || !list.length) return null;

  const TitleTag: any = isFullList
    ? 'h2'
    : 'h3';

  return (
    <div className={cn(
      s.root,
      isFullList && s.rootFull,
      isMobile ? s.rootMobile : s.rootDesktop,
      className,
    )}
    >
      {!isFullList && (
        <Typography
          variant="h2"
          className={s.title}
        >
          Типы в Дизайне человека
        </Typography>
      )}
      <div className={cn(
        s.list,
        isMobile ? s.listMobile : s.listDesktop,
        isFullList ? s.listFull : s.listShort,
      )}
      >
        {list.map((type, index) => {
          const key = `HumanDesignTypes-${type.slug}`;
          const celebrities = isMobile ? [type.celebrities[0]] : type.celebrities;

          return (
            <div key={key}>
              <Link
                className={cn(
                  s.type,
                  isMobile ? s.typeMobile : s.typeDesktop,
                  index === list.length - 1 && s.typeLast,
                )}
                to={`/dizain-cheloveka/tipy/${type.slug}/`}
                {...getTop100Markup(isMobile, top100Prefix, `interlinked_block::type::${type.slug}`)}
              >
                {!isMobile && img(type)}
                <div className={s.typeText}>
                  <Typography
                    variant="h3"
                    component={TitleTag}
                    className={
                      cn(
                        s.typeTitle,
                        isMobile ? s.typeTitleMobile : s.typeTitleDesktop,
                      )
                    }
                  >
                    {type.title}
                  </Typography>
                  {isMobile && (img(type))}
                  <Typography
                    variant="defaultMedium"
                    component="div"
                    className={cn(
                      s.typeDescription,
                      isMobile ? s.typeDescriptionMobile : s.typeDescriptionDesktop,
                    )}
                  >
                    {getShortDescription(type.short_description)}
                  </Typography>
                  <Typography
                    variant="defaultMedium"
                    component="div"
                    className={cn(s.typeCelebs, isMobile && s.typeCelebsMobile)}
                  >
                    {limitedCelebrities(celebrities)
                      .map((celebrity: HumanDesignCelebrityType, celebIndex: number) => (
                        <span key={celebrity.name}>
                          {celebrity.name}
                          {celebIndex === celebritiesLimit - 1 ? ' и другие' : ', '}
                        </span>
                      ))}
                  </Typography>
                </div>
              </Link>
              {ads && ads[index] && (
                <Ad
                  name={ads[index]}
                  wrapperClassName={isMobile ? s.adMobile : s.ad}
                />
              )}
            </div>
          );
        })}
      </div>
      {!isFullList && (
        <Typography
          variant="defaultBold"
          component="div"
          className={cn(s.buttonContainer, isMobile && s.buttonContainerMobile)}
        >
          <Link
            className={cn(s.button, isMobile ? s.buttonMobile : s.buttonDesktop)}
            to="/dizain-cheloveka/tipy/"
            {...getTop100Markup(isMobile, top100Prefix, 'interlinked_block::type::button_all_types')}
          >
            Смотреть все типы
          </Link>
        </Typography>
      )}
    </div>
  );
}

HumanDesignTypes.defaultProps = {
  types:      undefined,
  className:  '',
  isFullList: false,
  ads:        {},
};

export { HumanDesignTypes };
