import React, { useCallback } from 'react';
import cn from 'classnames';

import { Link } from 'react-router-dom';

import { Typography } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import {
  TYPES,
  PROFILES,
  DEFINITIONS,
  AUTHORITIES,
  STRATEGIES,
  FALSEI,
  CROSS,
  SEO_URLS,
} from 'config/constants/humanDesign';

import s from './styles.css';

interface IHumanDesignInterpretation {
  interpretation: HumanDesignResultType['interpretation']
  isMobile: boolean
  className?: string
}

function HumanDesignInterpretation({
  interpretation,
  isMobile,
  className,
}: IHumanDesignInterpretation) {
  const {
    top100Prefix,
  } = useTop100Context();

  const infoLineClasses = cn(s.infoLine, isMobile && s.infoLineMobile);

  const getInterpretation = useCallback(item => {
    const inner = (
      <>
        <Typography variant="defaultBold" className={s.infoTitle}>
          {item.title}
        </Typography>
        <Typography variant="defaultMedium" className={s.infoValue}>
          {item.value}
        </Typography>
      </>
    );

    if (item.link) {
      return (
        <Link
          className={cn(infoLineClasses, s.infoLink)}
          to={item.link}
          href={item.link}
          {...getTop100Markup(isMobile, top100Prefix, item.top100)}
        >
          {inner}
        </Link>
      );
    }

    return (
      <Typography
        variant="defaultMedium"
        component="div"
        className={infoLineClasses}
      >
        {inner}
      </Typography>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interpretation]);

  if (!interpretation) return null;

  const type = SEO_URLS[interpretation.type.toLowerCase()];
  const profile = PROFILES[interpretation.profile].slug;

  const interpretations = [{
    link:   `/dizain-cheloveka/tipy/${type}/`,
    top100: `info::${type}`,
    title:  'Тип',
    value:  TYPES[interpretation.type],
  }, {
    link:   `/dizain-cheloveka/profili/${profile}/`,
    top100: `info::${profile}`,
    title:  'Профиль',
    value:  `${PROFILES[interpretation.profile].rate} ${PROFILES[interpretation.profile].title}`,
  }, {
    title: 'Определение',
    value: DEFINITIONS[interpretation.definition],
  }, {
    title: 'Внутренний авторитет',
    value: AUTHORITIES[interpretation.inner_authority],
  }, {
    title: 'Стратегия',
    value: STRATEGIES[interpretation.type],
  }, {
    title: 'Ложное Я',
    value: FALSEI[interpretation.type],
  }, {
    title: 'Инкарнационный крест',
    value: CROSS[interpretation.incarnation_cross],
  }];

  return (
    <div className={cn(s.info, isMobile ? s.infoMobile : s.infoDesktop, className)}>
      {interpretations.map(item => getInterpretation(item))}
    </div>
  );
}

HumanDesignInterpretation.defaultProps = {
  className: '',
};

export { HumanDesignInterpretation };
