import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

const selectPadAndDebug = createSelector(
  [
    (state: IAppState) => state.runtime.currentPage,
    (state: IAppState) => state.runtime.currentSection,
    (state: IAppState) => state.runtime.currentParams.longreadId,
    (state: IAppState) => state.runtime.currentParams.url,
    (state: IAppState) => state.runtime.fullUrl,
  ],
  (
    currentPage,
    currentSection,
    longreadId,
    url,
    fullUrl,
  ) => ({
    currentPage,
    currentSection,
    longreadId,
    url,
    fullUrl,
  }),
);

const useSendRcmPageView = () => {
  const {
    currentPage,
    currentSection,
    longreadId,
    url,
    fullUrl,
  } = useSelector(selectPadAndDebug);

  useEffect(() => {
    if (!window.rcm || currentPage !== 'articles') return;

    if (currentSection === 'detail' && longreadId) {
      window.rcm('pageview', {
        itemId: parseInt(longreadId, 10),
      });
    } else if (currentSection === 'list' || currentSection === 'topic') {
      window.rcm('hubpageview', {
        hubId: url || 'main',
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullUrl]);
};

export default useSendRcmPageView;
