import { useCallback, useEffect } from 'react';

const useOnClickOutside = (
  ref: React.RefObject<HTMLElement>,
  callback: (e: MouseEvent) => void,
  apply: boolean,
) => {
  const onClickOutside = useCallback((e: MouseEvent) => {
    const { current } = ref;

    if (!current || (typeof current.contains === 'function' && !current.contains(e.target as HTMLElement))) {
      callback(e);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (apply) {
      window.addEventListener('click', onClickOutside);
    } else {
      window.removeEventListener('click', onClickOutside);
    }
  }, [apply]); // eslint-disable-line
};

export default useOnClickOutside;
