import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { DraftText } from 'common/components/DraftText';
import { Image } from 'common/components/Card/Image';
import { Ad } from 'common/components/Ad';
import { Typography } from 'common/components/Typography';

import { safeGet } from 'utils/safeGet';
import { parseDraft } from 'common/utils/draftParser';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './styles.css';

const selectRuntimeData = createSelector(
  [
    (state: IAppState) => state.runtime.currentParams.humanDesignDetailed,
  ],
  humanDesignDetailed => ({
    humanDesignDetailed,
  }),
);

interface IHumanDesignDetailed {
  detailed?: HumanDesignType['detailed']
  type?: string
  isMobile: boolean
  className?: string
}

function HumanDesignDetailed({
  detailed,
  type,
  isMobile,
  className,
}: IHumanDesignDetailed) {
  const {
    humanDesignDetailed,
  } = useSelector(selectRuntimeData);
  const {
    top100Prefix,
  } = useTop100Context();

  const currentDetailed = safeGet(() => detailed && detailed[type!][humanDesignDetailed!]);

  if (!humanDesignDetailed || !currentDetailed) return null;

  const {
    title,
    image,
    short_description: shortDescription,
    celebrities,
    draft,
  } = currentDetailed;

  const adShift = 1;

  const draftParsed = parseDraft(
    draft,
    humanDesignDetailed,
    isMobile,
    adShift,
  );

  return (
    <div className={cn(
      s.root,
      isMobile ? s.rootMobile : s.rootDesktop,
      className,
    )}
    >
      <Image
        wrapperClassName={cn(
          s.image,
          isMobile ? s.imageMobile : s.imageDesktop,
        )}
        image={{
          url:    image.url,
          s3:     true,
          source: {
            title: image.source.title,
            url:   image.url,
          },
        }}
        title={title}
        isLazy={false}
        isMobile={isMobile}
      />
      <Typography
        variant="defaultRegular"
        component="div"
        className={cn(
          s.description,
          isMobile ? s.descriptionMobile : s.descriptionDesktop,
        )}
      >
        {shortDescription}
      </Typography>
      <Typography
        variant="defaultMedium"
        className={cn(
          s.celebs,
          isMobile ? s.celebsMobile : s.celebsDesktop,
        )}
      >
        <span className={s.celebsTitle}>Знаменитости</span>
        <span className={s.celebsList}>
          {celebrities
            .map((celebrity: HumanDesignCelebrityType, index: number) => (
              <a
                href={celebrity.url}
                target="_blank"
                rel="noreferrer"
                key={celebrity.name}
                className={s.celeb}
                {...getTop100Markup(isMobile, top100Prefix, `${humanDesignDetailed}::сelebrity`)}
              >
                <span>{celebrity.name}</span>
                {index === celebrities.length - 1 ? '' : ', '}
              </a>
            ))}
        </span>
      </Typography>
      {!isMobile && (
        <Ad
          name="center"
          wrapperClassName={s.ad}
        />
      )}
      {isMobile && (
        <Ad
          name="content1"
          wrapperClassName={s.adMobile}
        />
      )}
      <DraftText
        data={draftParsed}
        title={title}
        className={cn(
          s.draftText,
          isMobile ? s.draftTextMobile : s.draftTextDesktop,
        )}
        isMainCard
      />
    </div>
  );
}

HumanDesignDetailed.defaultProps = {
  className: '',
  type:      '',
  detailed:  undefined,
};

export { HumanDesignDetailed };
